<template>
  <div id="addAtic" class="bg-white radius-4 padding-16 flex flex-direction"
    style="min-height: 800px;border-color: #D9D9D9;overflow: hidden;">
    <div class="flex align-center justify-start">
      <i @click="goback" class="el-icon-arrow-left" style="font-weight: 700;font-size: 30px;cursor: pointer;"></i>
    </div>

    <el-form id="articFrom" style="margin-top: 24px;text-align: left;" :model="ruleForm" :rules="rules" ref="ruleForm"
      label-width="100px" class="demo-ruleForm">
      <el-form-item label="发布类别" prop="article_category_id">
        <el-select @change="edit_category_id($event,'article_category_id')" v-model="ruleForm.article_category_id" placeholder="请选择">
          <el-option v-for="(item,index) in articType" :key="index" :label="item.article_category_title" :value="item.article_category_id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="标题" prop="article_title">
        <el-input @change="edit_category_id($event,'article_title')" id="fromtitle" v-model="ruleForm.article_title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="文章封面" prop="article_cover">
        <el-upload style="width: 320px;height: 160px;line-height: 160px;" class="avatar-uploader" ref="frontImg"
          :action="uploadUrl()" :show-file-list="false" :auto-upload="false" :data="upfileData"
          :with-credentials='false' :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
          :on-change="changeImg">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <div class="flex align-center">
        <el-form-item label="阅读量" prop="article_click">
          <el-input @change="edit_category_id($event,'article_click')" v-model="ruleForm.article_click" placeholder="请输入阅读量"></el-input>
        </el-form-item>
        <el-form-item label="点赞量" prop="article_support">
          <el-input @change="edit_category_id($event,'article_support')" v-model="ruleForm.article_support" placeholder="请输入点赞量"></el-input>
        </el-form-item>
        <el-form-item label="转发量" prop="article_share">
          <el-input @change="edit_category_id($event,'article_share')" v-model="ruleForm.article_share" placeholder="请输入转发量"></el-input>
        </el-form-item>
      </div>

        <Editor :catchData="editorChange" :content="content" edit_title='编辑文章'></Editor>
<!--      <el-form-item label="文章内容" prop="article_content">
        <el-input @change="edit_category_id($event,'article_content')" type="textarea" v-model="ruleForm.article_content" :rows="20" placeholder="请输入文章内容"></el-input>
      </el-form-item> -->

      <el-form-item label="状态" prop="article_state">
        <el-switch @change="edit_category_id($event,'article_state')" v-model="ruleForm.article_state" active-value="1" inactive-value="0"></el-switch>
      </el-form-item>

      <div class="flex align-center justify-center">
        <el-button type="primary" style="background-color: #4E9F5B;" @click="submitForm('ruleForm')">提 交</el-button>
      </div>

    </el-form>


  </div>

</template>

<script>
import {articleGet,articleAdd,articleEdit,articleCategory,fileFind,fileSave} from '@/api/http'
  import SparkMD5 from 'spark-md5'
  export default {
    components: {
    },
    data() {
      return {
        
        ruleForm: {
        },
        rules: {},
        articType:[],//文章类别
        /* 上传图片组*/
        imageUrl: '', //上传图片
        //上传图片额为参数
        upfileData: {
          key: ''
        },
        fileMd5: '', //文件md5
        currentArticID:'',
        editData:{
          article_id:''
        },
        content: '',
        content_nav:''//富文本内容
      }
    },
    created() {
      this.getAticType()
      if(localStorage.getItem('currentBusArticleID')){
        this.currentArticID=localStorage.getItem('currentBusArticleID')
        this.editData.article_id=localStorage.getItem('currentBusArticleID')

        this.getArticDel()
      }
    },
    methods: {
      //富文本返回的编辑内容
      editorChange(info) {
        this.content_nav=info
        console.log('ss',info)
        // 接收子组件传递过来的数据, 因为富文本中有内容,所以不用再赋值给 EditorCon,第一次我就是这么采坑的,然后果然掉进去了,出现页面回退的问题,呜呜呜.......
        // this.dataList.details = info
      },

      /* 获取文章详情*/
      getArticDel(){
        articleGet({article_id:this.currentArticID}).then(res=>{
          console.log('文章详情',res)
          if(res.data.code=='1'){
            this.ruleForm=res.data.data
            this.imageUrl=res.data.data.article_cover
            this.content=res.data.data.article_content
          }
        })
      },
      edit_category_id(e,type){
        console.log(e,type)
        this.editData[type]=e
        console.log(this.editData)
      },
      /* 获取文章类别*/
      getAticType(){
        articleCategory({article_category_type:2}).then(res=>{
          console.log('文章类别',res)
          if(res.data.code=='1'){
            this.articType=res.data.data.list
          }
        })
      },
      /* 返回 */
      goback(){
        localStorage.removeItem('currentBusArticleID')
        this.$router.push('/businessManage');
      },
      submitForm(formName) {
        let that = this
        // if(localStorage.getItem('fwb_nav')){
        //   this.ruleForm.article_content=localStorage.getItem('fwb_nav')
        //   this.editData.article_content=localStorage.getItem('fwb_nav')
        // }
        this.ruleForm.article_content=this.content_nav
        this.editData.article_content=this.content_nav

        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log('数据',this.ruleForm)
            if(localStorage.getItem('currentBusArticleID')){
              that.editRequset()
            }else{
              that.sendRequest(formName)
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      sendRequest(formName){
        articleAdd(this.ruleForm).then(res=>{
          console.log('添加分类',res)
          if(res.data.code=='1'){
            this.$message.success('添加成功')
            localStorage.removeItem('fwb_nav')
            this.$refs[formName].resetFields();
            this.$router.push('/bussinessManage');
          }else{
            this.$message.error(res.info)
          }
        })
      },
      editRequset(){
        articleEdit(this.editData).then(res=>{
          console.log('修改信息',res)
          if(res.data.code=='1'){
            this.$message.success('编辑成功')
            localStorage.removeItem('fwb_nav')
            localStorage.removeItem('currentBusArticleID')
            this.$router.push('/bussinessManage');
            // localStorage.setItem('','')
          }else{
            this.$message.error(res.info)
          }
        })
      },

      resetForm(formName) {
        this.$refs[formName].resetFields();
      },


      /* 选择文件*/
      changeImg(file) {
        this.imageUrl = URL.createObjectURL(file.raw);
        this.upfileImg(file)
      },
      /* 上传文件前*/
      beforeAvatarUpload(file) {
        console.log('上传前', file)
        let date = this.util.formatDate(new Date())
        let date_ = this.util.formatTime(new Date()).replace(/,/g, "")
        let randnum = (Math.random() * 9999999999).toFixed(0)
        let fileName = date_ + randnum
        this.upfileData.key = 'upload/xcx/' + date + '/' + fileName

        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isLt2M;
      },
      /* 上传成功*/
      handleAvatarSuccess(res, file) {
        this.save_file()
      },
      uploadUrl() {
        return 'https://oss-campus-print.yihuichuang.com/'
      },
      /* 计算文件md5*/
      upfileImg(file) {
        //console.log(file)
        const _this = this
        var fileReader = new FileReader();
        //此处打印file可看到file下的raw为文件属性
        var dataFile = file.raw;
        let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
        var spark = new SparkMD5.ArrayBuffer();
        //获取文件二进制数据
        fileReader.readAsArrayBuffer(dataFile)

        //异步执行函数
        fileReader.onload = function(e) {
          spark.append(e.target.result);
          var md5 = spark.end()
          console.log('MD5', md5)
          /* 查找文件是否存在*/
          _this.find_file(md5)
        }

      },
      /* 检查文件是否存在*/
      find_file(sparkEnd) {
        fileFind({
          file_md5: sparkEnd
        }).then(res => {
          console.log('检查', res)
          if (res.data.code == '1') { //文件存在，无需上传
            this.ruleForm.article_cover = res.data.data.file_id //文件id
            this.editData.article_cover = res.data.data.file_id //文件id
          } else { //文件不存在，上传文件
            this.upload('frontImg')
          }
        })
      },
      /* 文件上传*/
      upload(obj) {
        this.$refs[obj].submit(); //上传文件
      },
      /* 文件上传成功，保存文件*/
      save_file(file_uri) {
        fileSave({
          file_uri: this.upfileData.key
        }).then(res => {
          console.log('保存', res)
          if (res.data.code == '1') {
            this.ruleForm.article_cover = res.data.data.file_id //文件id
            this.editData.article_cover = res.data.data.file_id //文件id
          }
        })
      },
    }
  }
</script>

<style>
  #addAtic #articFrom .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  #addAtic #articFrom .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  #addAtic #articFrom .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 320px;
    height: 160px;
    line-height: 160px;
    text-align: center;
  }

  #addAtic #articFrom .avatar {
    width: 320px;
    height: 160px;
    display: block;
  }

  #addAtic #articFrom #fromtitle.el-input__inner{
    width: 30%;
  }
  #addAtic #articFrom .el-input__inner{
    height: 32px;
    line-height: 32px ;
  }
  #addAtic #articFrom .el-form-item__label{
    font-size: 14px;
    color: #000000;
  }
</style>
